import React, { useState, useEffect, useContext } from 'react'
import { Link, navigate } from 'gatsby'
import { Helmet } from 'react-helmet'
import { Global } from '@emotion/react'
import styled from '@emotion/styled'
import { useQueryParam, StringParam } from 'use-query-params'
import { useTranslation } from 'react-i18next'

import UserContext from 'context/user/UserContext'
import SubscriptionContext from 'context/subscription/SubscriptionContext'
import { useAuth0 } from 'utils/react-auth0-spa'
import {
  StripeCheckoutRedirectContainer,
  StripeCheckoutContentContainer,
  StripeCheckoutRedirectDescription,
  StripeCheckoutRedirectButton,
  ShapeContainer,
} from 'components/StripeCheckout'
import Loading from 'components/Loading'
import LottieShape from 'components/LottieShape'
import AbstractPaymentAnimation from 'components/Animations/AbstractPaymentAnimation'
import GlobalStyles from 'styles/GlobalStyles'

const InputBox = styled.input`
  width: 300px;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  background: #dbf8ff;
  margin-bottom: 3em;
`

const Redeem = () => {
  const SUBSCRIPTION_NAME = 'FluentWorlds Monthly'
  const REDEEM_PATH = '/payment/redeem/'
  const ACCOUNT_PATH = '/account/'
  const COUPON_CODE_QUERY_PARAM = 'coupon'
  const {
    productAccess,
    fetchUserProductAccess,
    userStateLoading,
  } = useContext(UserContext)
  const {
    subscriptions,
    fetchAvailableSitePlans,
    subscriptionStateLoading,
    createStripeSubscriptionSession,
  } = useContext(SubscriptionContext)
  const { auth0Loading, isAuthenticated, loginWithRedirect } = useAuth0()
  const { t, i18n, ready } = useTranslation('couponPage')
  const [couponCodeQueryParam] = useQueryParam(
    COUPON_CODE_QUERY_PARAM,
    StringParam,
  )
  const [couponCodeText, setCouponCodeText] = useState('')
  const [isCouponInvalid, setIsCouponInvalid] = useState(false) // flag to invalidate coupon so it won't be retried
  const [isRedirectAuth0Loading, setIsRedirectAuth0Loading] = useState(false)
  const [
    isRedirectStripeCheckoutLoading,
    setIsRedirectStripeCheckoutLoading,
  ] = useState(false)

  // when the user is logged in and subscriptions haven't yet been fetched then fetch the available site plans
  useEffect(() => {
    if (
      !auth0Loading &&
      !subscriptionStateLoading &&
      isAuthenticated &&
      subscriptions &&
      subscriptions.length === 0
    ) {
      fetchAvailableSitePlans()
      if (!productAccess) {
        fetchUserProductAccess()
      }
    }
  }, [
    auth0Loading,
    subscriptionStateLoading,
    fetchAvailableSitePlans,
    fetchUserProductAccess,
    isAuthenticated,
    productAccess,
    subscriptions,
  ])

  // prompt user to sign in if unauthenticated or create a Stripe checkout
  // session if the user has the coupon code in the query string
  useEffect(() => {
    if (
      !auth0Loading &&
      !isAuthenticated &&
      couponCodeQueryParam === undefined
    ) {
      setIsRedirectAuth0Loading(true)
      loginWithRedirect({
        redirect_uri: process.env.GATSBY_WEBSITE_URL + REDEEM_PATH,
        ui_locales: i18n.language.substring(0, 2),
      })
    } else if (
      !auth0Loading &&
      !isAuthenticated &&
      couponCodeQueryParam !== undefined
    ) {
      setIsRedirectAuth0Loading(true)
      loginWithRedirect({
        redirect_uri:
          process.env.GATSBY_WEBSITE_URL +
          REDEEM_PATH +
          '?' +
          COUPON_CODE_QUERY_PARAM +
          '=' +
          couponCodeQueryParam,
        ui_locales: i18n.language.substring(0, 2),
      })
    } else if (
      !auth0Loading &&
      isAuthenticated &&
      productAccess &&
      productAccess.fluentworlds
    ) {
      // the user has a subscription, navigate them to account page
      navigate(ACCOUNT_PATH)
    } else if (
      !auth0Loading &&
      !subscriptionStateLoading &&
      !isRedirectStripeCheckoutLoading &&
      isAuthenticated &&
      couponCodeQueryParam !== undefined &&
      subscriptions &&
      subscriptions.length > 0 &&
      productAccess &&
      !productAccess.fluentworlds &&
      !isCouponInvalid
    ) {
      setIsRedirectStripeCheckoutLoading(true)
      createStripeSubscriptionSession(
        SUBSCRIPTION_NAME,
        couponCodeQueryParam.trim(),
      )
      setIsCouponInvalid(true) // invalidate coupon code
      setIsRedirectStripeCheckoutLoading(false)
    }
  }, [
    auth0Loading,
    subscriptionStateLoading,
    isRedirectStripeCheckoutLoading,
    isAuthenticated,
    couponCodeQueryParam,
    subscriptions,
    i18n.language,
    loginWithRedirect,
    createStripeSubscriptionSession,
    productAccess,
    isCouponInvalid,
  ])

  const handleInputChange = (e) => {
    setCouponCodeText(e.currentTarget.value)
  }

  const submitCoupon = async () => {
    if (couponCodeText === '') {
      window.alert('Please enter in a valid coupon')
      return
    }

    setIsRedirectStripeCheckoutLoading(true)
    createStripeSubscriptionSession(SUBSCRIPTION_NAME, couponCodeText.trim())
    setIsRedirectStripeCheckoutLoading(false)
  }

  const showLoading =
    subscriptionStateLoading ||
    userStateLoading ||
    auth0Loading ||
    isRedirectAuth0Loading ||
    isRedirectStripeCheckoutLoading ||
    !ready

  return (
    <>
      <Helmet>
        <title>FluentWorlds</title>
      </Helmet>
      <Global styles={GlobalStyles} />
      {showLoading ? (
        <Loading />
      ) : (
        <>
          <StripeCheckoutRedirectContainer>
            <ShapeContainer>
              <LottieShape
                size={500}
                animationData={AbstractPaymentAnimation}
              />
            </ShapeContainer>
            <StripeCheckoutContentContainer>
              <StripeCheckoutRedirectDescription>
                {t('prompt')} {SUBSCRIPTION_NAME}:
              </StripeCheckoutRedirectDescription>
              <InputBox onChange={handleInputChange} />
              <StripeCheckoutRedirectButton onClick={() => submitCoupon()}>
                {t('submit')}
              </StripeCheckoutRedirectButton>
              <Link
                css={`
                  width: 300px;
                  margin-top: 3em;
                `}
                to={ACCOUNT_PATH}
              >
                <StripeCheckoutRedirectButton>
                  {t('goToAccount')}
                </StripeCheckoutRedirectButton>
              </Link>
            </StripeCheckoutContentContainer>
          </StripeCheckoutRedirectContainer>
        </>
      )}
    </>
  )
}

export default Redeem
